.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-logo1 {
  height: 15vmin;
  pointer-events: none;
}

.App-logo2 {
  height: 6vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.profilepic {
  border-radius: 50%;
  height: 35px;
  width: 35px;
  margin: 12px;
}

.topnavicon {
  margin: 18px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.main {
  background-color: #282c34;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.log-button {
  padding: 15px;
  background-color: #282c34;
  font-weight: bold;
  color: white;
  border: solid white 2px;
  border-radius: 10px;
}

.topnav {
  top: 0;
  width: 100%;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

li {
  float: left;
}

li:last-child {
  border-right: none;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 20px;
  text-decoration: none;
}

li a:hover:not(.active) {
  background-color: white;
  color: black;
}

.active {
  background-color: #04AA6D;
  font-weight: bold;
}

.lastchild {
  float: right;
  font-weight: bold;
}

.bottomnav {
  position: fixed;
  bottom: 0;
  width: 100%;
}